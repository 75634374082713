<template>
<div class="data-cockpit-ring-chart" v-loading="ringLoading" :id="chartId"></div>
</template>

<script>
// 省级数据驾驶舱 - 圆环图
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import { getStatDevice } from "api/apis.js";
import { reactive, computed, toRefs, inject } from 'vue';
export default {
  props: {
    chartData: { type: Object },
  },
  setup(prop, ctx) {
    const store = useStore();
    const echart = inject("echart");
    const state = reactive({
      chartId: computed(() => store.getters.UUID),
      ringLoading: false,
      ringData: [],
    });

    // 获取环形图数据
    const getData = () => {
      state.ringLoading = true;
      getStatDevice({
        t_agent_id: prop.chartData.t_agent_id,
        txt_device_type: prop.chartData.txt_device_type
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.ringData = response.data;
            // 渲染圆环图
            initRingChart();
            ctx.emit("showNull", false);
          } else {
            state.ringData = [];
            ctx.emit("showNull", true);
          }
        } else {
          state.ringData = [];
          ctx.emit("showNull", true);
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.ringLoading = false;
      })
    };

    // 渲染环形图
    const initRingChart = () => {
      echart.init(document.getElementById(state.chartId)).dispose();
      let ringChart = echart.init(document.getElementById(state.chartId));
      ringChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}台'
        },
        title: { text: "已分配设备总量：" + state.ringData.reduce((a, b) => a + b.cnt_device, 0) },
        legend: { data: state.ringData.map(t => t.txt_realname), bottom: '3%', width: "60%", type: "scroll", orient: 'horizontal' },
        series: [{
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: { show: false, position: 'center' },
          labelLine: { show: false },
          data: state.ringData.map(t => {
            return {
              value: t.cnt_device,
              name: t.txt_realname,
              t_agent_id: t.t_agent_id
            }
          })
        }]
      });

      // 环形图点击事件
      let tempLevel = prop.chartData.level;
      if (prop.chartData.level <= 3) {
        ringChart.on("click", function (params) {
          localStorage.setItem("dcForPro" + prop.chartData.level, JSON.stringify(prop.chartData.t_agent_id));
          console.log(prop.chartData.t_agent_id)
          tempLevel += 1;
          ctx.emit("getNextData", { level: tempLevel, t_agent_id: params.data.t_agent_id });
        })
        if (state.level != 1) {
          ctx.emit("showBtn", true);
        }
      }
      if (tempLevel == 2) {
        ctx.emit("showBtn", false);
      } else {
        ctx.emit("showBtn", true);
      }
    };

    return {
      ...toRefs(state),
      getData,
      initRingChart
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
