<template>
<div id="page" class="data-cockpit-charts-index">
  <el-row class="row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item label="地区" prop="txt_area_code">
        <el-select v-model="form.txt_area_code" size="small" @change="areaChange()">
          <el-option v-for="item in options.regionOption" :key="item.t_area_id" :label="item.txt_area_name" :value="item.txt_code" />
        </el-select>
      </el-form-item>
      <el-form-item label="合伙人" prop="t_agent_id">
        <el-select v-model="form.t_agent_id" size="small">
          <el-option v-for="item in options.agentOption" :key="item.t_agent_id" :label="item.txt_realname" :value="item.t_agent_id" />
        </el-select>
      </el-form-item>
      <el-form-item label="设备类型" prop="txt_type_code">
        <el-select v-model="form.txt_type_code" size="small">
          <el-option v-for="item in options.deviceOption" :key="item.t_device_type_id" :label="item.txt_type_name" :value="item.txt_type_code" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(null)">加载数据</el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <el-row class="dcci-sum-up row-center-start">
    <div class="dccisu-line">当前日期：<span>{{current.defaultDate}}</span></div>
    <div class="dccisu-line">本月订单总量（单）:<span>{{current.month}}</span></div>
    <div class="dccisu-line">当日订单总量（单）：<span>{{current.day}}</span></div>
    <div class="dccisu-line" @click="showCheckItemDialog()">查看检测项目分布情况</div>
  </el-row>
  <dc-check-test-item v-if="check.checkItemShow" :checkItem="check.checkItem" @close="()=>{check.checkItemShow=false;check.checkItem={};}" />
  <!-- 区域图部分 -->
  <div class="area-chart-part">
    <dc-area-chart ref="areaChartRef" v-if="chart.showArea" :chartData="chart.areaData" @showNull="(e) => {chart.showArea=!e;}" />
    <div class="no-data" v-else>暂无数据</div>
  </div>
  <!-- 环形图部分 -->
  <div class="ring-chart-part row-center-center">
    <div class="rcp-left">
      <el-button type="primary" v-if="chart.btnShow" @click="goBack()">返回</el-button>
      <dc-ring-chart ref="ringChartRef" v-show="chart.showRing" :chartData="chart.ringData" @showNull="(e) => {chart.showRing=!e;if(chart.ringData.type=='first'){getRankData(null)}}" @getNextData="(e)=>{getList(e)}" @showBtn="(e) => {chart.btnShow = e;}" />
      <div v-show="!chart.showRing" class="no-chart row-start">
        <div class="title">已分配设备总量：0</div>
        <div class="no-data column-center-center">暂无数据</div>
      </div>
    </div>
    <div class="rcp-right">
      <div class="rcpr-title-line row-center-between">
        <div class="rcpr-title row-center-start">
          <template v-if="current.level==2">市级合伙人</template>
          <template v-else-if="current.level==3">业务员</template>
          <template v-else-if="current.level==4">诊所</template>
          业绩排行榜 - Top5
          <div v-if="chart.rankingDataAll.length>5" class="rank-check-more" @click="checkMoreRank()">查看更多</div>
        </div>
        <el-radio-group v-model="btnActive" @change="radioChange()">
          <el-radio-button label="day">日</el-radio-button>
          <el-radio-button label="month">月</el-radio-button>
        </el-radio-group>
      </div>
      <table class="rcpr-table my-table" v-loading="loading.rankLoading">
        <tr>
          <th>地区</th>
          <th>姓名</th>
          <th>订单量（单）</th>
        </tr>
        <template v-if="chart.rankingData.length != 0">
          <tr v-for="(item, index) in chart.rankingData" :key="index">
            <td>{{item.txt_area_name}}</td>
            <td>{{item.txt_realname}}</td>
            <td>{{item.cnt_orders}}单</td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="3">暂无数据</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
  <dc-check-more-rank :checkRank="check.checkRank" v-if="check.checkRankShow" @close="()=> {check.checkRankShow=false;}" />
</div>
</template>

<script>
// 统计分析 - 省级数据驾驶舱
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import DcAreaChart from './comps/dcAreaChart.vue';
import DcRingChart from './comps/dcRingChart.vue';
import dcCheckTestItem from './comps/dcCheckTestItem.vue';
import DcCheckMoreRank from './comps/dcCheckMoreRank.vue';
import { onMounted, reactive, toRefs, computed, ref, nextTick } from 'vue';
import { getAreas, getEveryLevelInfo, getDeviceType, getCntOrders, getCntSubOrders } from "api/apis.js";
export default {
  components: { dcCheckTestItem, DcAreaChart, DcRingChart, DcCheckMoreRank },
  setup() {
    const route = useRoute();
    const areaChartRef = ref(null);
    const ringChartRef = ref(null);
    const moment = require("moment");
    const state = reactive({
      form: {
        txt_area_code: null,
        t_agent_id: null,
        txt_type_code: null
      },
      loading: {
        rankLoading: false
      },
      chart: {
        areaData: {},
        showArea: false,
        showRing: false,
        ringData: {},
        rankingData: [],
        rankingDataAll: [],
        btnShow: false
      },
      btnActive: "day",
      current: {
        month: 0,
        day: 0,
        defaultDate: computed(() => moment(new Date()).format('YYYY-MM-DD')),
        level: 2
      },
      check: {
        checkItemShow: false,
        checkItem: {},
        checkRank: [],
        checkRankShow: false
      },
      options: {
        regionOption: [],
        agentOption: [],
        deviceOption: []
      },
      areas_name: computed(() => {
        if (process.env.NODE_ENV == "development") {
          return ["0"];
          // todo
          // return ["120000", "340000", "370000"];
        } else {
          let path = route.path,
            menuAuth = JSON.parse(localStorage.getItem("menuAuth")),
            index = menuAuth.findIndex(t => t.path == path);
          if (menuAuth[index].txt_area_codes == "0") {
            return ["0"];
          } else {
            if (menuAuth[index].txt_area_codes) {
              return menuAuth[index].txt_area_codes.split(",");
            } else {
              return ["0"]
            }
          }
        }
      }),
    })

    onMounted(() => {
      getAreaOption();
      getDevices();
      localStorage.setItem("rankHasNewParams", JSON.stringify(null))
    })

    // 合伙人业绩排行 radio 切换事件
    const radioChange = () => {
      let t_agent_id = JSON.parse(localStorage.getItem("rankHasNewParams"));
      if (t_agent_id) {
        getRankData(t_agent_id);
      } else {
        getRankData(null);
      }
    };

    // 打开 合伙人业绩排行 查看更多 dialog 
    const checkMoreRank = () => {
      state.check.checkRank = {
        data: state.chart.rankingDataAll,
        level: state.current.level
      }
      state.check.checkRankShow = true;
    };

    // 返回上一级图表
    const goBack = () => {
      state.current.level -= 1;
      let key = "dcForPro" + state.current.level;
      state.btnShow = state.current.level == 2 ? false : true;
      setTimeout(() => {
        getList({ level: state.current.level, t_agent_id: JSON.parse(localStorage.getItem(key)) })
      }, 200)
    }

    // 获取合伙人业绩排行数据
    const getRankData = (t_agent_id) => {
      state.loading.rankLoading = true;
      getCntSubOrders({
        t_agent_id: t_agent_id ? t_agent_id : state.form.t_agent_id,
        txt_device_type: state.form.txt_type_code.split("-")[0],
        interval_type: state.btnActive.slice(0, 1)
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.chart.rankingDataAll = response.data;
            // 排序 - 展示top5
            state.chart.rankingDataAll.sort((a, b) => b.cnt_orders - a.cnt_orders);
            state.chart.rankingData = state.chart.rankingDataAll.slice(0, 5);
          } else {
            state.chart.rankingData = [];
            state.chart.rankingDataAll = [];
          }
        } else {
          state.chart.rankingData = [];
          state.chart.rankingDataAll = [];
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.check.checkRank = {};
        state.loading.rankLoading = false;
      })
    }

    // 获取数据
    const getList = (params) => {
      // 初始化时第一次加载所有数据
      if (params == null) {
        // 重置 “返回” 按钮
        state.btnShow = false;
        // 重置等级
        state.current.level = 2;
        state.chart.btnShow = false;
        if (Object.values(state.form).findIndex(t => t == null) == -1) {
          getCurrentMDOrders();
          // 重置 合伙人业绩排行 统计逻辑
          state.btnActive = "day";
          state.chart.areaData = {
            t_agent_id: state.form.t_agent_id,
            txt_device_type: state.form.txt_type_code.split("-")[0]
          }
          state.chart.ringData = {
            t_agent_id: state.form.t_agent_id,
            txt_device_type: state.form.txt_type_code.split("-")[0],
            level: state.current.level,
            type: "first"
          }
          state.chart.showArea = true;
          state.chart.showRing = true;
          nextTick(() => {
            areaChartRef.value.getData();
            ringChartRef.value.getData();
          })
        }
      } else {
        state.current.level = params.level;
        state.chart.ringData = {
          t_agent_id: params.t_agent_id,
          txt_device_type: state.form.txt_type_code.split("-")[0],
          level: state.current.level,
          type: "notFirst"
        }
        // 重置 合伙人业绩排行 统计维度
        state.btnActive = "day";
        state.chart.showRing = true;
        // 重新加载数据
        nextTick(() => {
          ringChartRef.value.getData();
          localStorage.setItem("rankHasNewParams", JSON.stringify(params.t_agent_id))
          getRankData(params.t_agent_id);
        })
      }
    };

    // 地区选择器切换事件
    const areaChange = () => {
      // 重新加载省代
      getAgentByAreaCode();
    }

    // 显示 检测项目TOP5 对话框
    const showCheckItemDialog = () => {
      if (state.current.level == 2) {
        state.check.checkItem = {
          t_agent_id: state.form.t_agent_id,
          txt_device_type: state.form.txt_type_code.split("-")[0]
        };
      } else {
        let key = "dcForPro2";
        state.check.checkItem = {
          t_agent_id: JSON.parse(localStorage.getItem(key)),
          txt_device_type: state.form.txt_type_code.split("-")[0]
        };
      }
      state.check.checkItemShow = true;
    };

    // 获取本月/日订单总量
    const getCurrentMDOrders = () => {
      getCntOrders({
        t_agent_id: state.form.t_agent_id,
        txt_device_type: state.form.txt_type_code.split("-")[0]
      }).then(response => {
        if (response.code == 200) {
          state.current.month = response.data[0].cnt_month_orders;
          state.current.day = response.data[0].cnt_today_orders;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取地区信息
    const getAreaOption = () => {
      getAreas({ parea_code: 0 }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            // 当前登录用户，地区权限中存在 “全国”
            if (state.areas_name.findIndex(t => t == "0") != -1) {
              state.options.regionOption = response.data;
            } else {
              // 不存在 全国 权限，格式化数据
              for (let i = 0; i < state.areas_name.length; i++) {
                let index = response.data.findIndex(t => t.txt_code == state.areas_name[i]);
                if (index != -1) {
                  state.options.regionOption.push(response.data[index]);
                }
              }
            }
            state.form.txt_area_code = state.options.regionOption[0].txt_code;
            // 根据 地区code 获取代理
            getAgentByAreaCode();
          } else {
            state.options.regionOption = [];
            state.form.txt_area_code = null;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 根据地区code获取省代
    const getAgentByAreaCode = () => {
      getEveryLevelInfo({
        int_agent_level: 1,
        txt_area_code: state.form.txt_area_code,
        limit: 999,
        page: 1
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.options.agentOption = response.data.data;
            state.form.t_agent_id = state.options.agentOption[0].t_agent_id;
            getList(null);
          } else {
            state.options.agentOption = [];
            state.form.t_agent_id = null;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取设备类型
    const getDevices = () => {
      getDeviceType().then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.options.deviceOption = response.data;
            state.form.txt_type_code = state.options.deviceOption[0].txt_type_code;
            getList(null);
          } else {
            state.options.deviceOption = [];
            state.form.txt_type_code = null;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    return {
      ...toRefs(state),
      getAreaOption,
      getAgentByAreaCode,
      getDevices,
      getCurrentMDOrders,
      getList,
      showCheckItemDialog,
      areaChange,
      areaChartRef,
      ringChartRef,
      getRankData,
      radioChange,
      goBack,
      checkMoreRank
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select-dropdown__item {
  font-size: 16px;
}

.el-select .el-input__inner {
  font-size: 16px;
}

.data-cockpit-charts-index {
  .dcci-sum-up {
    .dccisu-line {
      margin-right: 14px;

      &:not(:first-child) {
        margin-right: 18px;
        color: #fff;
        padding: 10px 14px;
        border-radius: 10px;
        background-color: #54a5f1;
      }

      &:last-child {
        color: #54a5f1;
        padding: 0 0;
        cursor: pointer;
        text-decoration: underline;
        background-color: #fff;
      }

      span {
        margin-left: 10px;
      }
    }
  }

  .area-chart-part {
    margin-top: 8px;
    height: 430px;
    border-bottom: 2px solid #e2dfdf;

    .data-cockpit-area-chart {
      height: 100%;
    }
  }

  .ring-chart-part {
    margin-top: 10px;
    height: 450px;

    .rcp-left {
      height: 100%;
      width: calc(100% / 2);
      border-right: 2px solid #e2dfdf;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .no-chart {
        height: 100%;
        width: 100%;

        .title {
          font-size: 18px;
          font-weight: bold;
        }

        .no-data {
          height: calc(100% - 25px);
        }
      }

      .data-cockpit-ring-chart {
        height: 100%;
        width: calc(100% - 60px);
      }
    }

    .rcp-right {
      height: 100%;
      width: calc(100% / 2);
      padding-left: 6px;
      box-sizing: border-box;

      .rcpr-title-line {
        margin-bottom: 6px;
      }

      .rcpr-title {
        width: 60%;
        font-weight: bold;

        .rank-check-more {
          color: #54a5f1;
          cursor: pointer;
          margin-left: 6px;
          text-decoration: underline;
        }
      }
    }
  }

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
