<template>
<div class="data-cockpit-area-chart" v-loading="areaLoading" :id="chartId"></div>
</template>

<script>
// 省级数据驾驶舱 - 区域图
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import { getOrdersTrend } from "api/apis.js";
import { reactive, computed, toRefs, onMounted, inject } from 'vue';
export default {
  props: {
    chartData: { type: Object },
  },
  setup(prop, ctx) {
    const store = useStore();
    const moment = require("moment");
    const echart = inject("echart");
    const state = reactive({
      chartId: computed(() => store.getters.UUID),
      areaLoading: false,
      areaData: []
    });

    onMounted(() => {
      // getData();
    })

    // 获取区域图数据
    const getData = () => {
      state.areaData = [];
      state.areaLoading = true;
      getOrdersTrend({
        t_agent_id: prop.chartData.t_agent_id,
        txt_device_type: prop.chartData.txt_device_type,
        day: "7"
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            for (let i = 6; i >= 0; i--) {
              let tempDate = moment().subtract(i, "days").format("YYYY-MM-DD"),
                tempIndex = response.data.findIndex(t => t.dt_order == tempDate);
              if (tempIndex != -1) {
                state.areaData.push({
                  dt_order: tempDate,
                  cnt_orders: response.data[tempIndex].cnt_orders
                })
              } else {
                state.areaData.push({
                  dt_order: tempDate,
                  cnt_orders: 0
                })
              }
            }
            initAreaChart();
          } else {
            state.areaData = [];
            ctx.emit("showNull", true);
          }
        } else {
          state.areaData = [];
          ctx.emit("showNull", true);
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.areaLoading = false;
      })
    };

    // 渲染区域图
    const initAreaChart = () => {
      echart.init(document.getElementById(state.chartId)).dispose();
      let areaChart = echart.init(document.getElementById(state.chartId));
      areaChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: state.areaData.map(t => t.dt_order)
        },
        yAxis: { type: 'value' },
        series: [{
          name: "订单数量",
          data: state.areaData.map(t => t.cnt_orders),
          type: 'line',
          smooth: true,
          areaStyle: {}
        }]
      });
    };

    return {
      ...toRefs(state),
      getData
    }
  }
}
</script>

<style lang="scss" scoped>
.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dcac-chart {
  width: 100%;
  height: 100%;
}
</style>
