<template>
<el-dialog v-model="visible" :title="title" :close-on-click-modal="false" custom-class="data-cockpit-check-rank-dialog" :before-close="close">
  <div class="dialog-body data-cockpit-check-rank">
    <table class="dccrd-table my-table">
      <tr>
        <th>地区</th>
        <th>姓名</th>
        <th>订单量（单）</th>
      </tr>
      <template v-if="tableData.length!=0">
        <tr v-for="(item, index) in tableData" :key="index">
          <td>{{item.txt_area_name}}</td>
          <td>{{item.txt_realname}}</td>
          <td>{{item.cnt_orders}}单</td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="3">暂无数据</td>
        </tr>
      </template>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 省级数据驾驶舱 - 合伙人业绩排行 - 查看更多
import { onMounted, reactive, toRefs } from 'vue';
export default {
  props: {
    checkRank: { type: Object }
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      tableData: [],
      title: "业绩排行"
    });

    const close = () => {
      ctx.emit("close");
    };

    onMounted(() => {
      state.tableData = prop.checkRank.data;
      if (prop.checkRank.level == 2) {
        state.title = "市级合伙人" + state.title;
      } else if(prop.checkRank.level == 3) {
        state.title = "业务员" + state.title;
      } else if (prop.checkRank.level == 4) {
        state.title = "诊所" + state.title;
      }
    })

    return {
      ...toRefs(state),
      close,
    }
  }
}
</script>

<style lang="scss">
.data-cockpit-check-rank-dialog {
  width: 580px;
}
</style>
