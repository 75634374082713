<template>
<el-dialog v-model="visible" title="检测项目" :close-on-click-modal="false" custom-class="data-cockpit-check-item-dialog" :before-close="close">
  <div class="dialog-body data-cockpit-check-item" v-loading="loading">
    <el-radio-group v-model="btnActive" @change="radioChange()">
      <el-radio-button label="day">日</el-radio-button>
      <el-radio-button label="month">月</el-radio-button>
    </el-radio-group>
    <table class="dccid-table my-table">
      <tr>
        <th>项目名称</th>
        <th>订单量（单）</th>
        <th>占比（%）</th>
      </tr>
      <template v-if="tableData.length!=0">
        <tr v-for="(item, index) in tableData" :key="index">
          <td>{{item.txt_tp_name}}</td>
          <td>{{item.cnt_orders}}</td>
          <td>{{item.pct_orders}}%</td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="3">暂无数据</td>
        </tr>
      </template>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 市级合伙人详情
import { onMounted, reactive, toRefs } from 'vue';
import { getCntTpOrder } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
  props: {
    checkItem: { type: Object }
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      loading: false,
      btnActive: "day",
      tableData: []
    });

    const close = () => {
      ctx.emit("close");
    };

    onMounted(() => {
      checkDisTable();
    })

    // radio切换事件
    const radioChange = () => {
      checkDisTable();
    };

    // 查看检测项目分布情况 - 表格弹框
    const checkDisTable = () => {
      state.loading = true;
      getCntTpOrder({
        t_agent_id: prop.checkItem.t_agent_id,
        txt_device_type: prop.checkItem.txt_device_type,
        interval_type: state.btnActive.slice(0, 1)
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.tableData = response.data;
          } else {
            state.tableData = [];
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    return {
      ...toRefs(state),
      close,
      checkDisTable,
      radioChange
    }
  }
}
</script>

<style lang="scss">
.data-cockpit-check-item-dialog {
  width: 580px;

  .data-cockpit-check-item {
    .dccid-table {
      margin-top: 10px;
    }
  }
}
</style>
